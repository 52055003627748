// face 1 hands 1

function Clock(p5) {
	this.width = 275;
	this.height = 275;
	this.diameter = 270;
	this.radius = this.diameter / 2;
	this.face = new URL("/src/lib/images/face-4.png", import.meta.url);
	this.setFace = face => (this.face = face);
	this.setHands = (hour, minute) => (this.hands = {hour, minute});
	this.translate = () => p5.translate(this.width / 2, this.height / 2);
	this.drawFrame = () => {
		let frameStroke = 5;
		let pointStroke = 2 * frameStroke;
		p5.push();
		p5.translate(this.width / 2, this.height / 2);
		p5.strokeWeight(frameStroke);
		p5.strokeWeight(pointStroke);
		p5.point(0, 0);
		p5.pop();
	};

	this.drawHands = n => {
		if (n % 5 !== 0) return;
		n = n / 5;
		let radius = this.diameter / 2;
		let location12 = radius * 0.89;
		let location24 = radius * 0.65;
		let textSize = radius * 0.15;
		let angle = p5.map(n, 0, 12, 0, 360);
		p5.push();
		this.translate();
		let {x, y} = p5.createVector(0, -location12).rotate(angle);
		p5.textSize(textSize);
		p5.text(n, x, y);
		let {x: x2, y: y2} = p5.createVector(0, -location24).rotate(angle);
		p5.textSize(textSize * 0.85);
		p5.text(n + 12, x2, y2);
		p5.pop();
	};

	this.drawTicks = n => {
		let location = this.radius * 0.79;
		let angle = p5.map(n, 0, 60, 0, 360);
		p5.push();
		this.translate();
		p5.rotate(angle);
		p5.translate(0, -location);
		p5.strokeWeight(n % 5 === 0 ? this.radius * 0.02 : this.radius * 0.01);
		p5.line(0, 0, 0, 5);
		p5.pop();
	};

	this.drawMinsHand = () => {
		let length = 0.72 * this.radius; //72
		let weight = this.radius / 53;
		p5.push();
		this.translate();
		p5.rotate(180 + p5.minute() * 6);
		p5.strokeWeight(weight);
		p5.line(0, 0, 0, length);
		p5.line(0, -16, 0, 0);
		p5.pop();
	};

	this.drawSecondHand = () => {
		let length = 0.74 * this.radius;
		let weight = this.radius / 35;
		p5.push();
		this.translate();
		p5.rotate(180 + p5.second() * 6);
		p5.stroke(255, 0, 0, 255);
		p5.line(0, -15, 0, length);
		p5.strokeWeight(weight);
		p5.line(0, -12, 0, -this.radius / 7);
		p5.pop();
	};

	this.drawHourHand = () => {
		let length = 0.5 * this.radius; //0.5
		let weight = this.radius / 38; //38
		p5.push();
		this.translate();
		p5.rotate(180 + p5.hour() * 30 + p5.minute() * 0.5);
		p5.strokeWeight(weight);
		p5.line(0, 0, 0, length);
		p5.line(0, -16, 0, 0);
		p5.pop();
	};

	this.drawText = () => {
		let fontSize = this.radius / 11;
		p5.push();
		this.translate();
		p5.textSize(fontSize);
		let m = p5.minute() < 10 ? `0${p5.minute()}` : `${p5.minute()}`;
		let ex = p5.hour() >= 12 ? "pm" : "am";
		// p5.stroke(255);
		p5.text(`${p5.hour()}:` + m + ex, 0, -this.radius / 3.5);
		p5.text("feidorian.dev", 0, this.radius / 3.5);
		p5.pop();
	};
}

const Sketch = p => {
	let clock = new Clock(p);
	p.preload = () => {
		clock.setFace(p.loadImage(clock.face.href));
	};

	p.setup = () => {
		p.createCanvas(clock.width, clock.height);
		p.angleMode(p.DEGREES);
		p.textAlign(p.CENTER, p.CENTER);
	};

	p.draw = () => {
		p.background(30, 32, 33);
		p.push();
		clock.translate();
		p.ellipse(0, 0, clock.diameter, clock.diameter);
		p.pop();
		p.image(clock.face, 2, 2, clock.diameter, clock.diameter);
		clock.drawFrame();
		clock.drawText();
		clock.drawSecondHand();
		clock.drawMinsHand();
		clock.drawHourHand();
	};
};

let myp5 = new p5(Sketch, "digital-clock4");
